import Vue from "vue";
import Api from '@/core/Api';
import moment from 'moment'
Api.moment = moment;
Vue.prototype.$api = Api;
Array.prototype.findID = function(id){
  var check = this.find(a => a.id === id)
  if(check === undefined){
    return {}
  }
  return check
}
